import * as React from 'react';
import { SharedTextStyle } from './SharedTextStyle';
import styled from 'styled-components';

import { getAssetLinkByHandle } from 'utils/getAssetLink';

import { LazyImage } from 'components/LazyImage';
import { Images } from '../CourseLanding/Certificates';
import { useLandingDifferences } from '../CourseLanding/Differences';
import Modal from '../CourseLanding/Modal';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.section`
  ${SharedTextStyle}

  display: grid;
  grid-auto-rows: max-content;
  gap: 4px;

  .wrapper-certificates {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.02);
    }
  }

  ${fromScreen(776)} {
    gap: 24px;
    grid-template-columns: 332px minmax(0, 1fr);
    align-items: center;
  }

  ${fromScreen(1144)} {
    gap: 40px;
    justify-content: space-between;
    grid-template-columns: 440px minmax(0, 540px);
  }

  .header-info {
    display: grid;
    grid-auto-rows: max-content;
    gap: 12px;
    ${fromScreen(458)} {
      gap: 16px;
    }
    ${fromScreen(776)} {
      gap: 32px;
    }
  }
`;

const LinearthinkingCertificates = () => {
  const [showModal, setShowModal] = React.useState(false);
  const data = useLandingDifferences();
  const diff = data?.gcms?.jsonContanier?.content?.differences?.[0];
  return (
    <Main className="linear-thinking-certificates">
      <div className="header-info">
        <h2 className="section-title">Giấy chứng nhận Linearthinking</h2>
        <div className="section-description">
          Linearthinking đã được Cục Sở Hữu Trí Tuệ cấp bằng chứng nhận tên gọi
          “Linearthinking” và tác quyền các bài viết liên quan đến
          Linearthinking.
        </div>
      </div>

      <div
        onClick={() => {
          setShowModal(true);
        }}
        className="wrapper-certificates"
      >
        <LazyImage
          src={getAssetLinkByHandle({ handle: 'qMw9ShUzTjKCpv6X0gB4' })}
          alt="Giấy chứng nhận Linearthinking"
        />
      </div>

      <Modal
        title={
          diff.info.content.link.modalTitle || diff.info.content.link.title
        }
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <Images images={diff.info.content.images} />
      </Modal>
    </Main>
  );
};

export default LinearthinkingCertificates;
