import * as React from 'react';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import { ButtonLoadMore } from '../ButtonLoadMore/ButtonLoadMore';
import { fromScreen } from '../../utils/media-query/responsive.util';
import isNil from 'lodash/isNil';

const Action = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  ${fromScreen(776)} {
    grid-template-columns: 344px;
  }
`;

const ListLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 24px;

  ${fromScreen(375)} {
    grid-template-columns: repeat(auto-fit, minmax(344px, 1fr));
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 40px;
`;

export const ListWithLoadMoreBtn = ({
  apiCacheKey,
  apiFn,
  txtSeeMore = 'Xem thêm',
  renderCard,
  initialData = undefined
}) => {
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      apiCacheKey,
      ({ pageParam = 0 }) =>
        apiFn({
          page: pageParam
        }),
      {
        initialData,
        getNextPageParam: lastPage => {
          if (lastPage?.last) {
            return undefined;
          }
          if (isNil(lastPage?.number)) {
            return 0;
          }
          return lastPage?.number + 1;
        }
      }
    );
  return (
    <Container>
      <ListLayout>
        {(data?.pages || [])
          .flatMap(p => p.content)
          ?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {renderCard(item, index)}
              </React.Fragment>
            );
          })}
      </ListLayout>
      {hasNextPage && (
        <Action>
          <ButtonLoadMore
            text={txtSeeMore}
            loading={isFetchingNextPage}
            onClick={fetchNextPage}
          />
        </Action>
      )}
    </Container>
  );
};
