import * as React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';

import { fromScreen } from '../../utils/media-query/responsive.util';

const Main = styled.div`
  position: relative;
  padding: 32px 16px;
  ${fromScreen(458)} {
    margin: 0 auto;
    width: 425px;
    padding: 32px 0;
  }

  ${fromScreen(776)} {
    width: 712px;
    bottom: 68px;
    height: 132px;
  }

  ${fromScreen(1144)} {
    width: 1080px;
    height: 157px;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: -16px;
    > * {
      margin: 8px;
    }

    ${fromScreen(776)} {
      background: ${colorsV2.white100};
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 20px;

      margin: 0;
      > * {
        margin: 0;
      }
    }
  }

  .item {
    border: 1px solid ${colorsV2.gray20};
    border-radius: 8px;
    padding: 12px;
    display: grid;
    place-items: center;
    text-align: center;
    gap: 4px;
    width: 43vw;
    height: 108px;

    ${fromScreen(458)} {
      width: 200.5px;
    }
    ${fromScreen(776)} {
      padding: 0 27.5px;

      &:first-child {
        padding-left: 48px;
      }
      &:last-child {
        padding-right: 48px;
      }

      border: none;
      border-right: 1px solid ${colorsV2.gray20};
      margin: 32px 0;
      width: auto;
      height: auto;
      flex: 1;
      border-radius: 0;
      &:last-child {
        border-right: none;
      }
    }
    ${fromScreen(1144)} {
      padding-left: 75.5px;
      padding-right: 75.5px;
      &:first-child {
        padding-left: 100px;
      }
      &:last-child {
        padding-right: 100px;
      }

      margin: 40px 0;
      gap: 8px;
    }
  }
  .item-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${colorsV2.primary100};

    ${fromScreen(1144)} {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .item-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${colorsV2.black80};
    ${fromScreen(1144)} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const HARD_DATA = [
  {
    id: 1,
    title: '20,000+',
    description: 'Học viên thay đổi Tư Duy học Tiếng Anh'
  },
  {
    id: 2,
    title: '500+',
    description: 'Học viên đạt IELTS 7.0+ sau khóa học'
  },
  {
    id: 3,
    title: '50+',
    description: 'Học viên đạt IELTS 8.0+ sau khóa học'
  }
];

export const LinearthinkingHighlights = () => {
  return (
    <Main className="linearthinking-highlights">
      <div className="content-wrapper">
        {HARD_DATA.map(item => {
          const { id, title, description } = item;
          return (
            <div className="item" key={id}>
              <div className="item-title">{title}</div>
              <div className="item-description">{description}</div>
            </div>
          );
        })}
      </div>
    </Main>
  );
};
