import styled from 'styled-components';

import { deviceBreakpoints2 } from '../../utils/hooks/useDeviceQuery.hook';

export const HeadingContainer = styled.div`
  margin-bottom: 24px;

  @media screen and ${deviceBreakpoints2.mobile} {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    > *:first-child {
      margin-right: 8px;
    }
  }

  @media screen and ${deviceBreakpoints2.tablet},
    ${deviceBreakpoints2.desktop} {
    display: grid;
    gap: 8px;
    grid-template-columns: max-content max-content;
    align-items: center;
  }
`;
