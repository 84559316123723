/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';

import LinearthinkingCertificates from 'shared/Linearthinking/LinearthinkingCertificates';
import LinearthinkingTestimonial from 'shared/Linearthinking/LinearthinkingTestimonial';
import YoutubeVideoCourses from 'shared/Linearthinking/YoutubeVideoCourses';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import StudentReviews from 'shared/StudentCourseLanding/StudentReviews';
import { DolLinearthinkingList } from '../components/DolLinearthinking/DolLinearthinkingList';

import { LinearthinkingHighlights } from 'shared/Linearthinking/LinearthinkingHighlights';
import { SharedTextStyle } from 'shared/Linearthinking/SharedTextStyle';
import { fromScreen } from 'utils/media-query/responsive.util';
import MainLayout from '../components/Layout/MainLayout';
import { GCMSNewsAboutDolLinearthinking } from '../shared/CourseLanding/NewsAboutDolLinearthinking';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import { LinearThinkingHeaderBanner } from '../shared/Linearthinking/LinearthinkingHeaderBannerV2';
import { StudentSpeakAboutLinear } from '../shared/Linearthinking/StudentSpeakAboutLinear';
import MySEO from '../shared/MySEO';
import { PDFViewer } from '../shared/PDFViewer';
import { getAssetLinkByHandle } from '../utils/getAssetLink';
import { UserPixelTracking } from '../utils/userPixelTracking';
import { graphql } from 'gatsby';

const Main = styled.div`
  ${fromScreen(776)} {
    .review-title {
      text-align: center;
    }
  }
  .responsive-section {
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    ${fromScreen(458)} {
      max-width: 425px;
      padding-left: 0;
      padding-right: 0;
    }

    ${fromScreen(776)} {
      row-gap: 100px;
      max-width: 712px;
    }

    ${fromScreen(1144)} {
      max-width: 1080px;
    }
  }
`;

const ContentPDFViewer = styled(PDFViewer)`
  height: 268px;
  ${fromScreen(776)} {
    height: 448px;
  }
  ${fromScreen(1144)} {
    height: 680px;
  }
`;

const PDFSectionMain = styled.div`
  ${SharedTextStyle};
  display: grid;
  gap: 40px;
  grid-auto-rows: max-content;
`;

const PDFSection = () => {
  return (
    <PDFSectionMain>
      <h2 className="heading-center-desktop section-title">
        Cơ sở khoa học đằng sau Linearthinking
      </h2>

      <ContentPDFViewer
        url={getAssetLinkByHandle({ handle: '8TVRQkZjRnGOHYuisf4n' })}
      />
    </PDFSectionMain>
  );
};

const Space = styled.div`
  > .responsive-section {
    padding-top: 32px;
    padding-bottom: 32px;
    ${fromScreen(776)} {
      padding-top: 80px;
      padding-bottom: 100px;
    }
  }
`;

const LandingLinearthinkingPage = ({ location, data }) => {
  const onClick = () => {
    UserPixelTracking.clickEvent();
  };

  return (
    <MainLayout
      renderCustomHeader={() => <HeaderSchedulePage />}
      noHeader
      hideSideCTA
      showFooterRegister
    >
      <MySEO
        slug={location.pathname}
        h1="Linearthinking - Hệ phương pháp Tiếng Anh Tư Duy dành cho người Việt"
        hidePostfix
        title="LinearThinking - Tiếng Anh Tư Duy Cho Người Việt"
        description="LinearThinking là phương pháp học tiếng anh tư duy dành cho người Việt độc quyền do DOL sáng tạo giúp việc học tiếng Anh trở nên đơn giản hơn."
      />
      <FixedTopBarScholarshipScheduleNOffline />

      <Main className="gioi-thieu-linearthinking-page">
        <LinearThinkingHeaderBanner
          title={`<span class="highlight">Linearthinking</span> - Hệ phương pháp Tiếng Anh Tư Duy dành cho người Việt`}
          description="Hệ phương pháp Linearthinking độc quyền chỉ có tại DOL giúp bạn học Tiếng Anh nhanh chóng và dễ dàng hơn."
          onClickFunc={onClick}
        />

        <LinearthinkingHighlights />

        <Space>
          <div className="responsive-section">
            <LinearthinkingCertificates />
          </div>
          <div className="responsive-section">
            <StudentSpeakAboutLinear />
          </div>
          <div className="responsive-section">
            <PDFSection />
          </div>

          <div className="responsive-section">
            <YoutubeVideoCourses />
          </div>
          <div>
            <LinearthinkingTestimonial />
          </div>
          <div className="responsive-section">
            <DolLinearthinkingList data={data} />
          </div>
          <div className="responsive-section">
            <StudentReviews
              title="Học viên nói gì về Linearthinking"
              program="all"
              columns={{ default: 3, 1143: 2, 775: 1 }}
            />
          </div>
          <div className="responsive-section">
            <GCMSNewsAboutDolLinearthinking loadMoreOnMobile />
          </div>
        </Space>
      </Main>
    </MainLayout>
  );
};

export default LandingLinearthinkingPage;

export const pageQuery = graphql`
  query LinearThinkingBlogQuery {
    allLinearThinkingBlog {
      nodes {
        id
        page {
          dol {
            title
            description
            fileInfo {
              url
              alt
              path
              name
            }
          }
          url
        }
      }
    }
  }
`;
