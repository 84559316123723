import Button from '@dolstaff/shared/es/Button';
import { Link } from 'gatsby';
import * as React from 'react';
import ProgressiveImage from 'react-progressive-image';
import styled from 'styled-components';

import { Typography } from '../../components/DesignSystemV2/Typography';
import { Sketch1 } from '../../components/Sketch/Sketch1';
import { Sketch4 } from '../../components/Sketch/Sketch4';
import { Sketch5 } from '../../components/Sketch/Sketch5';
import { colorsV2 } from '../../style/colors-v2';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { fromScreen } from '../../utils/media-query/responsive.util';
import DolInfo from '../DolInfo';

const Layout = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: max-content;
  align-items: center;
  margin: 0 auto;
  text-align: left;

  .header-info {
    position: relative;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-auto-rows: max-content;
    row-gap: 20px;
    .description {
      margin-bottom: 4px;
    }
  }

  .banner-img {
    position: relative;
    display: none;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  button {
    border-radius: 8px !important;
  }

  .star-icon {
    display: none;
    position: absolute;
    width: 24.5px;
    height: 24.5px;
    top: 0;
    right: 34px;
  }

  .spinning-arrow-icon {
    display: none;
    position: absolute;
    width: 58px;
    height: 24px;
    top: calc(100% + 36.85px);
    left: 77.6px;
    transform: matrix(-0.74, -0.67, -0.67, 0.74, 0, 0);
  }

  .wave-icon {
    display: none;
    position: absolute;
    width: 64px;
    height: 12px;
    top: 362px;
    left: -24px;
  }

  ${fromScreen(458)} {
    max-width: 425px;
  }

  ${fromScreen(776)} {
    max-width: 712px;
    grid-template-columns: 433px minmax(0, 1fr);
    gap: 24px;
    .banner-img,
    .star-icon {
      display: block;
    }
  }

  ${fromScreen(1144)} {
    max-width: 1080px;
    grid-template-columns: 576px minmax(0, 1fr);
    column-gap: 68px;

    .star-icon {
      top: 20px;
      right: 72px;
    }
    .wave-icon,
    .spinning-arrow-icon {
      display: block;
    }
  }
`;

const Container = styled.div`
  position: relative;
  padding: 32px 16px;
  background-color: ${colorsV2.paper};

  .heading {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: ${colorsV2.black100};
    margin: 0;

    .highlight {
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        background: ${colorsV2.yellow100};
        top: 26px;
        left: 0;
        height: 12px;
        width: 100%;
        opacity: 0.35;
        z-index: -1;
        bottom: 3px;
        ${fromScreen(1144)} {
          top: 32px;
        }
      }
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colorsV2.black80};
  }

  ${fromScreen(458)} {
    padding: 32px 0;
  }

  ${fromScreen(776)} {
    padding: 100px 0 161px;
  }

  ${fromScreen(1144)} {
    .heading {
      font-size: 40px;
    }
    .description {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const LinearThinkingHeaderBanner = props => {
  const { title, description, onClickFunc } = props;

  return (
    <Container className="linear-thinking-header-banner-v2">
      <Layout>
        <div className="header-info">
          <h1 className="heading" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="description">{description}</p>
          <DolInfo>
            {({ dolInfo }) => (
              <Link
                target="_blank"
                href={dolInfo.messenger.href}
                rel="noreferrer noopener"
              >
                <Button
                  size="large"
                  className="btn-action"
                  type="primary"
                  marginBottom="0"
                  onClick={onClickFunc}
                >
                  <Typography
                    variant="semi-bold/14-24"
                    color={colorsV2.white100}
                  >
                    Nhắn tin DOL qua FB
                  </Typography>
                </Button>
              </Link>
            )}
          </DolInfo>
          <Sketch4 className="banner-icon spinning-arrow-icon" />
        </div>
        <div className="banner-img">
          <ProgressiveImage
            src={getAssetLinkByHandle({
              handle: '5BAq2BuJRYG2FkHumGOE'
            })}
            placeholder={getAssetLinkByHandle({
              handle: '5BAq2BuJRYG2FkHumGOE',
              width: 10
            })}
          >
            {src => <img src={src} alt="linear-thinking-banner" />}
          </ProgressiveImage>
          <Sketch5 className="banner-icon wave-icon" />
          <Sketch1 className="banner-icon star-icon" />
        </div>
      </Layout>
    </Container>
  );
};
