import * as React from 'react';

import styled from 'styled-components';

import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from '../../utils/media-query/responsive.util';
import MemoIconDoubleQuoteClose from './icons/IconDoubleQuoteClose';
import MemoIconDoubleQuoteOpen from './icons/IconDoubleQuoteOpen';
import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';

const Main = styled.section`
  display: grid;
  place-items: center;
  text-align: center;
  gap: 32px;
  background-color: ${colorsV2.paper};
  padding: 32px 16px;

  .content {
    display: grid;
    gap: 32px;
    ${fromScreen(458)} {
      width: 425px;
    }
    ${fromScreen(776)} {
      width: 650px;
    }
  }

  .double-quote-icon {
    color: ${colorsV2.primary100};
    font-size: 13px;
    position: relative;
    top: -4px;
  }

  ${fromScreen(776)} {
    padding-top: 64px;
    padding-bottom: 64px;
    .double-quote-icon {
      font-size: 24px;
    }
  }
`;

const LinearthinkingTestimonial = () => {
  return (
    <Main className="linearthinking-testimonial">
      <div className="content">
        <ResponsiveTypography.Paragraph
          breakpoints={[0, 776]}
          variant={['semi-bold/20-28', 'semi-bold/32-40']}
        >
          <span className="double-quote-icon">
            <MemoIconDoubleQuoteOpen />
          </span>
          {
            ' Phương pháp Linearthinking không còn gì hịn hơn nữa :3 Nói chung là ai học đảm bảo GHIỀN. '
          }
          <span className="double-quote-icon">
            <MemoIconDoubleQuoteClose />
          </span>
        </ResponsiveTypography.Paragraph>

        <div className="author">
          <ResponsiveTypography.Paragraph
            color="black100"
            breakpoints={[0, 776]}
            variant={['medium/16-24', 'medium/24-32']}
          >
            Như Thảo
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph
            color="black80"
            breakpoints={[0, 776]}
            variant={['regular/12-20', 'regular/14-20']}
          >
            Học viên khoá IELTS 6.0
          </ResponsiveTypography.Paragraph>
        </div>
      </div>
    </Main>
  );
};

export default LinearthinkingTestimonial;
