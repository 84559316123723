export const apiUrls = {
  publicService: {
    GET_BLOGS_OVERVIEW: 'api/blogs/overview',
    GET_LISTED_IELTS_BLOGS_LANDING: '/api/ielts-blogs/landing',
    GET_NEW_SITES_QUOTE: '/api/news-sites/quoted',
    GET_NEW_SITES_FEATURE: '/api/news-sites/featured',
    GET_NEW_SITES_LISTED: '/api/news-sites/articles/listed'
  }
};

export const apiCacheKeys = {
  publicService: {
    GET_LISTED_IELTS_BLOGS_LANDING: 'GET_LISTED_IELTS_BLOGS_LANDING',
    GET_BLOGS_OVERVIEW: 'GET_BLOGS_OVERVIEW',
    GET_NEW_SITES_QUOTE: 'GET_NEW_SITES_QUOTE',
    GET_NEW_SITES_FEATURE: 'GET_NEW_SITES_FEATURE',
    GET_NEW_SITES_LISTED: 'GET_NEW_SITES_LISTED'
  }
};
