import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Sizing = styled.div`
  width: 100%;
  height: 0;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const AspectRatio = props => {
  const { ratio = 3 / 2, children, ...rest } = props;

  let paddingBottom;

  if (Array.isArray(ratio)) {
    paddingBottom = ratio.map(r => `${100 / r}%`);
  } else {
    paddingBottom = `${100 / ratio}%`;
  }

  return (
    <Container>
      <Sizing style={{ paddingBottom }} />
      <Content {...rest}>{children}</Content>
    </Container>
  );
};

AspectRatio.propTypes = {
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  children: PropTypes.node
};
