/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import IconButtonPlay from './icons/IconButtonPlay';
import { colorsV2 } from '../../style/colors-v2';
import Modal from '../CourseLanding/Modal';
import { VideoPlayer } from './YoutubeVideoCourses';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { fromScreen } from '../../utils/media-query/responsive.util';
import CourseButton from 'shared/CourseLanding/CourseButton';
import { SharedTextStyle } from './SharedTextStyle';

const videos = [
  {
    id: '2',
    title: 'Trà My',
    videoUrl: 'https://www.youtube.com/embed/OhMesTzyi2I',
    thumbnailUrl: 'b3sTKZNpT0uYahXT8zMr'
  },
  {
    id: '1',
    title: 'Trung Nghĩa',
    videoUrl: 'https://www.youtube.com/embed/D6-gDpjvAvE',
    thumbnailUrl: 'zVue6RnwQRJi7PJNoNbg'
  }
];

const CardVideoMain = styled.div`
  text-align: left;
  display: grid;
  gap: 12px;
  width: 100%;

  .video-box {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .video-wrapper {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: grid;
      place-items: center;
      transition: 0.2s all;
      cursor: pointer;

      &:active {
        .play-button {
          transform: scale(0.9);
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .play-button {
        transition: 0.2s all;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3.72727px);
        width: 64px;
        min-width: 64px;
        height: 64px;
        min-height: 64px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        font-size: 30px;
        color: ${colorsV2.white100};
      }

      > * {
        pointer-events: none;
      }

      &:hover {
        .play-button {
          color: ${colorsV2.blue60};
        }
      }
    }
  }
`;
const CardVideo = ({ data, onPlayVideo }) => {
  const { title, thumbnailUrl } = data;
  return (
    <CardVideoMain className={cl('card-video')}>
      <div
        onClick={e => {
          e.preventDefault();
          onPlayVideo();
        }}
        className="video-box"
      >
        <div className="video-wrapper">
          <div className="overlay">
            <div className="play-button">
              <IconButtonPlay />
            </div>
          </div>
          <img
            alt={title}
            src={getAssetLinkByHandle({ handle: thumbnailUrl })}
          />
        </div>
      </div>
    </CardVideoMain>
  );
};

const Main = styled.div`
  ${SharedTextStyle}
  .content-wrapper {
    display: grid;
    gap: 24px;
    grid-auto-rows: max-content;

    ${fromScreen(776)} {
      align-items: center;
      grid-template-columns: 356px minmax(0, 1fr);
      .infoes {
        order: 2;
      }
      .section-actions {
        order: 1;
      }
    }
    ${fromScreen(1144)} {
      align-items: flex-start;
      gap: 40px;
      grid-template-columns: 540px minmax(0, 1fr);
    }
  }
  .infoes {
    display: grid;
    gap: 12px;
    ${fromScreen(458)} {
      gap: 16px;
    }
    ${fromScreen(776)} {
      gap: 32px;
    }
  }
  .section-actions {
    margin-top: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    ${fromScreen(458)} {
      margin-top: 0;
    }
  }

  &&& {
    .course-button {
      padding: 10px 12px;
      ${fromScreen(1144)} {
        padding: 16px 18px;
      }
    }
    .course-button-text {
      font-size: 16px;
      line-height: 24px;
      ${fromScreen(1144)} {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
`;

export const StudentSpeakAboutLinear = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <Main>
      <div className="content-wrapper">
        <div className="infoes">
          <h2 className="section-title">Học viên nói gì về Linearthinking</h2>
          <div className="section-description">
            Cùng lắng nghe chia sẻ của các bạn học viên về hệ phương pháp
            Linearthinking độc quyền chỉ có tại DOL nhé!
          </div>
          <div className="section-actions">
            {videos.map((item, index) => {
              const { title, id } = item;
              return (
                <CourseButton
                  key={id}
                  active={activeIndex === index}
                  content={title}
                  onClick={() => {
                    setActiveIndex(index);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="video-preview">
          <CardVideo
            data={videos[activeIndex]}
            onPlayVideo={() => {
              setVideoUrl(videos[activeIndex].videoUrl);
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <VideoPlayer videoUrl={videoUrl} autoPlay />
      </Modal>
    </Main>
  );
};
