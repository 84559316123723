import * as React from 'react';
import cl from 'classnames';
import { toWidth } from '../../utils/media-query/media-query';

const isMobile = () => {
  return window.matchMedia(toWidth(767)).matches;
};

export const PDFViewer = ({ url, className }) => {
  const viewer = React.useRef(null);
  React.useEffect(() => {
    import('@pdftron/pdfjs-express-viewer')
      .then(mod => {
        return mod.default;
      })
      .then(WebViewer =>
        WebViewer(
          {
            path: '/webviewer/lib/public/',
            licenseKey: process.env.PDF_LICENSE_KEY,
            disabledElements: [
              'leftPanelButton',
              'viewControlsButton',
              'searchButton',
              'selectToolButton',
              'menuButton',
              'divider'
            ],
            initialDoc: url
          },
          viewer.current
        ).then(instance => {
          const Tools = instance.Core.Tools;
          instance.UI.setToolMode(Tools.ToolNames.PAN);
          instance.UI.setHeaderItems(header => {
            header.getHeader('default').delete(1); // remove default divider
            header.getHeader('default').push({
              img: 'icon-header-full-screen',
              index: -1,
              type: 'actionButton',
              element: 'fullScreenButton',
              onClick: () => {
                instance.UI.toggleFullScreen();
              }
            });
          });

          //
          if (isMobile()) {
            instance.setLayoutMode(instance.LayoutMode.Single);
          } else {
            instance.setLayoutMode(instance.LayoutMode.Facing);
          }
          instance.setSwipeOrientation('horizontal');
        })
      );
  }, [url]);

  return <div className={cl('webviewer', className)} ref={viewer} />;
};
