import * as React from 'react';
import styled from 'styled-components';
import { SORT_BY_ORDERING_ASC } from '../../constant';
import { apiCacheKeys } from '../../apis/api.urls';
import { ListWithLoadMoreBtn } from './ListWithLoadMoreBtn';
import { DolLinearthinkingCard } from './Card/DolLinearthinkingCard';
import { SITE_URL } from '../../apis/baseURL';
import { SharedTextStyle } from 'shared/Linearthinking/SharedTextStyle';
import { useFromScreens } from 'utils/media-query/responsive.util';
import { fixUrl } from 'utils';

const Main = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 40px;
  margin: 0 auto;

  ${SharedTextStyle}
`;

const listingParams = {
  size: 6,
  sort: SORT_BY_ORDERING_ASC
};

const getPageData = (allLinearThinkingBlog, page, size) => ({
  pageParams: [page],
  pages: [
    {
      content: allLinearThinkingBlog?.nodes?.slice(
        page * size,
        Math.max((page + 1) * size),
        allLinearThinkingBlog?.nodes?.length
      ),
      pageable: {
        sort: {
          sorted: true,
          unsorted: false,
          empty: false
        },
        pageNumber: page,
        pageSize: size,
        offset: page * size,
        paged: true,
        unpaged: false
      },
      last: page * size > allLinearThinkingBlog?.nodes?.length,
      sort: {
        sorted: true,
        unsorted: false,
        empty: false
      },
      first: page === 0,
      size,
      number: page,
      empty: false
    }
  ]
});

export const DolLinearthinkingList = ({ data }) => {
  const { allLinearThinkingBlog } = data;
  const [, from776] = useFromScreens([0, 776]);
  const finalListingParams = {
    ...listingParams,
    size: from776 ? 6 : 3
  };

  return (
    <Main className="dol-linearthinking-list">
      <h2 className="heading-center-desktop section-title">
        Bài viết về Linearthinking
      </h2>
      <ListWithLoadMoreBtn
        txtSeeMore="Xem thêm bài viết"
        apiCacheKey={[
          apiCacheKeys.publicService.GET_BLOGS_OVERVIEW,
          finalListingParams
        ]}
        initialData={getPageData(
          allLinearThinkingBlog,
          0,
          finalListingParams.size
        )}
        apiFn={newParams => {
          const { page } = newParams;
          return getPageData(
            allLinearThinkingBlog,
            page,
            finalListingParams.size
          )?.pages[0];
        }}
        renderCard={item => {
          const title = item?.page?.dol?.title;
          const description = item?.page?.dol?.description;
          const thumbnail = item?.page?.dol?.fileInfo;
          const url = item?.page?.url;
          const alt = item?.page?.dol?.alt;
          return (
            <DolLinearthinkingCard
              url={fixUrl(`${SITE_URL}/${url}`)}
              thumbnail={thumbnail?.url}
              title={title}
              description={description}
              alt={alt}
            />
          );
        }}
      />
    </Main>
  );
};
