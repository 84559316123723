import { colorsV2 } from '../../style/colors-v2';
import { Typography } from '../../components/DesignSystemV2/Typography';
import * as React from 'react';

export const SectionTitle = props => {
  return (
    <Typography
      type="span"
      className="text"
      variant="semi-bold/24-32"
      desktopVariant="semi-bold/32-40"
      style={{ color: colorsV2.black100 }}
      as="h2"
      {...props}
    />
  );
};

export const LandingSectionTitle = props => {
  return (
    <Typography
      type="span"
      className="text"
      variant="bold/32-40"
      desktopVariant="bold/40-48"
      style={{ color: colorsV2.black100, textAlign: 'center' }}
      as="h2"
      {...props}
    />
  );
};
